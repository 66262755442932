import { Component, OnInit, inject } from '@angular/core';
import { NavigationEnd, Router, RouterOutlet } from '@angular/router';

import { VersionService } from './services';
import { SnackbarService } from './views/services';
import { LanguageService } from './views/services/language.service';
import { filter, tap } from 'rxjs/operators';
import { ProgressBarMode, MatProgressBarModule } from '@angular/material/progress-bar';
import { AwareAuthService } from '@appbolaget/aware-auth';
import { environment } from '@env';

@Component({
    selector: 'app-root',
    templateUrl: './app.component.html',
    styleUrls: ['./app.component.scss'],
    standalone: true,
    imports: [MatProgressBarModule, RouterOutlet],
})
export class AppComponent implements OnInit {
    title = 'app';

    progressbar: { mode: ProgressBarMode; show: boolean } = {
        mode: 'indeterminate',
        show: false,
    };

    #awareAuth = inject(AwareAuthService);

    constructor(
        public router: Router,
        public version: VersionService,
        public snackbar: SnackbarService,
        private languageService: LanguageService,
    ) {
        this.languageService.init();
    }

    ngOnInit() {
        this.router.events.pipe(filter((event) => event instanceof NavigationEnd)).subscribe(() => {
            window.scrollTo(0, 0);
        });

        this.#awareAuth.onLogout$
            .pipe(
                tap(({ state }) => {
                    if (state.oauthProvider && state.oauthSignoutRequest && state.oauthUnit) {
                        const url = `${environment.api.url}/authenticate/signout?options=${JSON.stringify({
                            [state.oauthProvider]: { unit: state.oauthUnit, redirect_url: `http://${window.location.host}/auth/login` },
                        })}&token=${state.token}`;

                        window.location.href = url;
                    }
                }),
            )
            .subscribe();

        // this.actions$
        //     .pipe(
        //         ofActionDispatched(Logout),
        //         tap(() => {
        //             this.router.navigate(['/auth']);
        //         })
        //     )
        //     .subscribe();
    }

    // private _initIdleListener() {
    //     this.idle.watch();
    // }

    // private _setIdleConfig() {
    //     // sets an idle timeout of 3600 seconds (1 hour)
    //     this.idle.setIdle(3600);
    //     // sets a timeout period of 7200 seconds (2 hours). after 2 hours of inactivity, the user will be considered timed out.
    //     this.idle.setTimeout(7200);
    //     // sets the default interrupts, in this case, things like clicks, scrolls, touches to the document
    //     this.idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    //     this.idle.onTimeout.subscribe(() => {
    //         this.router.navigate(['/login']);

    //         setTimeout(() => {
    //             this.auth.signout();
    //         }, 300);
    //     });

    //     this.keepalive.interval(5);

    //     this.keepalive.onPing.subscribe(() => {
    //         const signedInAt = moment(this.auth.getSignedInAt());

    //         if (signedInAt.isValid()) {
    //             if (signedInAt.isBefore(moment().subtract(8, 'hours'))) {
    //                 this.router.navigate(['/auth']);
    //                 this.auth.signout();
    //             }
    //         }
    //     });
    // }
}
