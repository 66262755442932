import { Routes } from '@angular/router';

import { Pages } from './pages.component';
import { GodGuard } from '../../helpers/guards';
import { awareAuthGuard } from '@appbolaget/aware-auth';

export const routes: Routes = [
    {
        path: 'login',
        redirectTo: '/auth/login',
    },
    {
        path: 'login/new-password/:token',
        redirectTo: '/auth/new-password/:token',
    },
    {
        path: 'login/verify-password/:token',
        redirectTo: '/auth/verify-password/:token',
    },
    {
        path: 'login/delete-client/:token',
        redirectTo: '/auth/delete-client/:token',
    },
    {
        path: 'login/forgot-password',
        redirectTo: '/auth/forgot-password',
    },
    {
        path: 'login/oauth/attendo',
        redirectTo: '/auth/oauth/attendo',
    },
    {
        path: 'auth',
        loadChildren: () => import('@modules/auth/auth.routing').then((m) => m.routes),
    },
    {
        path: 'app',
        canActivate: [awareAuthGuard],
        component: Pages,
        children: [
            { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
            {
                path: 'calendar',
                loadChildren: () => import('@pages/calendar/calendar.routing').then((m) => m.routes),
            },
            {
                path: 'clients',
                loadChildren: () => import('@pages/clients/clients.routing').then((m) => m.routes),
            },
            {
                path: 'locations',
                loadChildren: () => import('@pages/locations/locations.routing').then((m) => m.routes),
            },
            {
                path: 'me',
                loadChildren: () => import('@pages/clients/show/show.routing').then((m) => m.routes),
                data: {
                    isMe: true,
                },
            },
            {
                path: 'messages',
                loadChildren: () => import('@pages/messages/messages.routes').then((c) => c.routes),
            },
            {
                path: 'roles',
                loadChildren: () => import('@pages/olympus/roles/roles.routing').then((m) => m.routes),
                canActivate: [GodGuard],
            },
            {
                path: 'posts',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
            },
            {
                path: 'categories',
                loadChildren: () => import('@pages/categories/categories.routing').then((m) => m.routes),
            },
            {
                path: 'sights',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'sight' },
            },
            {
                path: 'approutes',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'approute' },
            },
            {
                path: 'events',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'event' },
            },
            {
                path: 'occasions',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'occasion' },
            },
            {
                path: 'pages',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'page' },
            },
            {
                path: 'news',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'news' },
            },
            {
                path: 'stories',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'story' },
            },
            {
                path: 'noticeboard',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'noticeboard' },
            },
            {
                path: 'weekschedule',
                loadChildren: () => import('@pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'weekschedule', configType: 'weekly_schedule' },
            },
            {
                path: 'navigations',
                loadChildren: () => import('@pages/navigation/navigation.routing').then((m) => m.routes),
            },
            {
                path: 'units',
                loadChildren: () => import('@pages/units/units.routing').then((m) => m.routes),
            },
            {
                path: 'olympus',
                loadChildren: () => import('@pages/olympus/olympus.routing').then((m) => m.routes),
                canActivate: [GodGuard],
            },
            {
                path: 'organisations',
                loadChildren: () => import('@pages/olympus/organisations/organisations.routing').then((m) => m.routes),
                canActivate: [GodGuard],
            },
            {
                path: 'notifications',
                loadChildren: () => import('@pages/notification/notification.routing').then((m) => m.routes),
            },
            // {
            //     path: 'lunch',
            //     loadChildren: () => import('@pages/lunch/lunch.module').then((m) => m.LunchModule),
            // },
            {
                path: 'lunch/list',
                loadChildren: () => import('app/views/pages/posts/posts.routing').then((m) => m.routes),
                data: { type: 'lunch_week' },
            },
            {
                path: 'week-scheduler',
                loadComponent: () => import('@pages/week-scheduler/week-scheduler.page').then((m) => m.WeekSchedulerPageComponent),
            },
            {
                path: 'feedback',
                loadChildren: () => import('@modules/feedback/feedback.routing').then((m) => m.routes),
            },
            {
                path: 'dashboard',
                loadChildren: () => import('@pages/dashboard/dashboard.routing').then((m) => m.routes),
                data: { root: true },
            },
            {
                path: 'statistics',
                loadComponent: () => import('@pages/statistics/statistics.page').then((m) => m.StatisticsPageComponent),
            },
            {
                path: 'contracts',
                loadChildren: () => import('@modules/contract/contract.routing').then((m) => m.routes),
            },
            { path: '**', redirectTo: 'dashboard', pathMatch: 'full' },
        ],
    },
];
