import { AsyncPipe } from '@angular/common';
import { Component, ElementRef, HostListener, OnInit, ViewChild } from '@angular/core';
import { ReactiveFormsModule, UntypedFormControl } from '@angular/forms';
import { MatAutocompleteModule, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';
import { MatOptionModule } from '@angular/material/core';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { AwareHttpService, IAwareCollection } from '@appbolaget/aware-http';
import { Unit } from '@appbolaget/aware-model';
import { AwareSecurityService } from '@appbolaget/aware-security';
import { Actions, Store, ofActionDispatched } from '@ngxs/store';
import { ShowUnitSearch } from 'app/state/app.actions';
import { SetActiveUnit } from 'app/state/unit.actions';
import { debounceTime, Observable, switchMap, take, tap } from 'rxjs';

@Component({
    selector: 'app-spotlight',
    templateUrl: 'spotlight.component.html',
    standalone: true,
    imports: [
        MatFormFieldModule,
        MatInputModule,
        MatAutocompleteModule,
        ReactiveFormsModule,
        MatProgressBarModule,
        AsyncPipe,
        MatOptionModule,
    ],
    styles: [
        `
            mat-form-field {
                height: 60px !important;
                overflow: hidden;
            }
        `,
    ],
})
export class SpotlightComponent implements OnInit {
    UNIT_SEARCH_VISIBLE: boolean;
    IS_LOADING: boolean;

    @HostListener('document:keydown.meta.k', ['$event']) onKeyDownMeta(e) {
        e.preventDefault();

        this.toggleUnitSearch();
    }

    @HostListener('document:keydown.control.k', ['$event']) onKeyDownCtrl(e) {
        e.preventDefault();

        this.toggleUnitSearch();
    }

    @HostListener('document:keydown.escape', ['$event']) onEscape(_) {
        this.UNIT_SEARCH_VISIBLE = false;
    }

    @ViewChild('unitSearchInput') unitSearchInput: ElementRef;

    unitSearchControl = new UntypedFormControl();
    units$: Observable<IAwareCollection<Unit>>;
    constructor(
        private api: AwareHttpService,
        private ass: AwareSecurityService,
        private store: Store,
        private actions$: Actions,
    ) {}

    ngOnInit() {
        this._initListeners();
    }

    displayFn(unit: Unit): string {
        return unit?.title ? unit.title : '';
    }

    async setActiveUnit(e: MatAutocompleteSelectedEvent) {
        const unit = e.option.value;

        await this.store.dispatch(new SetActiveUnit(unit)).toPromise();

        this.UNIT_SEARCH_VISIBLE = false;
        this.unitSearchControl.setValue('');
    }

    toggleUnitSearch() {
        this.UNIT_SEARCH_VISIBLE = !this.UNIT_SEARCH_VISIBLE;

        if (this.UNIT_SEARCH_VISIBLE) {
            setTimeout(() => {
                this.unitSearchInput.nativeElement.focus();
            }, 100);
        }
    }

    private async _initListeners() {
        const scope = (await this.ass.isGod().pipe(take(1)).toPromise()) ? 'global' : 'units';

        this.units$ = this.unitSearchControl.valueChanges.pipe(
            debounceTime(300),
            tap(() => (this.IS_LOADING = true)),
            switchMap((query) =>
                this.api
                    .get('units/search')
                    .with('parent')
                    .parameter('query', query)
                    .parameter('scope', scope)
                    .stream('down')
                    .toCollection(Unit)
                    .execute(),
            ),
            tap(() => (this.IS_LOADING = false)),
        );

        this.actions$
            .pipe(
                ofActionDispatched(ShowUnitSearch),
                tap(() => {
                    this.toggleUnitSearch();
                }),
            )
            .subscribe();
    }
}
